import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from '@material-tailwind/react';
import { useState } from 'react';
import { Container, Flex } from '@radix-ui/themes';

import Day from '../components/Day';
import { Day1Schedule, Day2Schedule } from '../data/constants';

const Schedule = () => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <Container
      size='4'
      align='center'
      width='100%'
      py={{ initial: '6', md: '8' }}
      px={{ initial: '4', md: '0' }}
    >
      <Flex
        direction='column'
        align='start'
        gap={{ initial: '6', md: '8' }}
        width='100%'
      >
        <h1 className='text-primaryColor text-2xl md:text-5xl font-bold'>
          Event Schedule & Venue
        </h1>

        <Tabs value={activeTab} className='w-full'>
          <TabsHeader
            className='rounded-none border-b-0 bg-transparent p-0'
            indicatorProps={{
              className:
                'bg-transparent border-b-2 border-primaryColor shadow-none rounded-none',
            }}
          >
            <Tab
              value={1}
              onClick={() => setActiveTab(1)}
              className={`text-lg md:text-xl ${
                activeTab === 1
                  ? 'text-primaryColor font-bold'
                  : 'text-blackColor'
              }`}
            >
              DAY 1
            </Tab>
            <Tab
              value={2}
              onClick={() => setActiveTab(2)}
              className={`text-lg md:text-xl ${
                activeTab === 2
                  ? 'text-primaryColor font-bold'
                  : 'text-blackColor'
              }`}
            >
              DAY 2
            </Tab>
          </TabsHeader>

          <TabsBody className='mt-10 -mb-10'>
            <TabPanel value={1}>
              <Day schedule={Day1Schedule} title='Day 1' date='14th Sept' />
            </TabPanel>

            <TabPanel value={2}>
              <Day schedule={Day2Schedule} title='Day 2' date='15th Sept' />
            </TabPanel>
          </TabsBody>
        </Tabs>
      </Flex>
    </Container>
  );
};

export default Schedule;
