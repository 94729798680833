import { Container, Flex } from '@radix-ui/themes';

const Attend = () => {
  return (
    <Container
      size='4'
      align='center'
      width='100%'
      py={{ initial: '6', md: '8' }}
      px={{ initial: '4', md: '0' }}
    >
      <Flex
        direction='column'
        align='start'
        gap={{ initial: '6', md: '8' }}
        width='100%'
      >
        <Flex
          direction='column'
          align='start'
          gap={{ initial: '4', md: '6' }}
          width='100%'
        >
          <h1 className='text-primaryColor text-2xl md:text-5xl font-bold'>
            Who should attend this event?
          </h1>

          <p className='text-sm md:text-base w-full leading-7'>
            The Pharmaceutical Development: Integrating Quality by Design and
            Bio-pharmaceutic Risk Assessment workshop is recommended, but not
            limited for:
            <ul className='list-inside list-disc'>
              <li>
                Pharmaceutical and healthcare professionals involved in Product
                Development, Process Development.
              </li>
              <li>
                Regulatory Affairs, Quality Control and Quality Assurance.
              </li>
              <li>
                Academic Researchers focusing on product and process
                development.
              </li>
              <li>
                Teachers and academicians in Pharmaceutical and biotechnological
                fields.
              </li>
              <li>
                Students aspiring to pursue career in pharmaceutical/
                biotechnological sectors.
              </li>
            </ul>
          </p>
        </Flex>

        <Flex
          direction='column'
          align='start'
          gap={{ initial: '4', md: '6' }}
          width='100%'
        >
          <h1 className='text-primaryColor text-2xl md:text-5xl font-bold'>
            Visa and Accomodation
          </h1>

          <p className='text-sm md:text-base w-full leading-7'>
            Organizing committee would be happy to assist outstation
            participants in need of visa and/or accommodation. Please share your
            requirements and registration details over email with
            biotechworkshop@dubai.bits-pilani.ac.in
            <br />
            Hotels close to workshop venue:
            <ul className='list-inside list-disc'>
              <li>Radisson Blu, Dubai Silicon Oasis</li>
              <li>Premier Inn, Dubai Silicon Oasis</li>
              <li>Best Western Hotels and Resorts</li>
              <li>Abidos Hotel Apartment, Dubailand</li>
            </ul>
            Accommodation can be provided to participants (on request) in single
            occupancy hostel room with shared bath within campus premises (AED
            150 per night including dinner).
          </p>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Attend;
