import { Container, Flex } from '@radix-ui/themes';
import Person from '../components/Person';

const Instructors = () => {
  return (
    <Container
      size='4'
      width='100%'
      align='center'
      py={{ initial: '6', md: '8' }}
      px={{ initial: '4', md: '0' }}
    >
      <Flex
        width='100%'
        align='center'
        direction='column'
        gap={{ initial: '6', md: '8' }}
      >
        <Flex
          width='100%'
          align='start'
          direction='column'
          gap={{ initial: '4', md: '6' }}
        >
          <h1 className='text-primaryColor text-2xl md:text-5xl font-bold'>
            Profile of Instructors
          </h1>
          <Flex
            direction='column'
            align='start'
            gap={{ initial: '4', md: '6' }}
            width='100%'
          >
            <Flex
              direction={{ initial: 'column', md: 'row' }}
              gap={{ initial: '4', md: '6' }}
              align='center'
              width='100%'
            >
              <Person
                imgUrl='assets/about/committee/trainers/naseem.jpg'
                position='Instructor'
                name='Dr. Naseem Ahmad Charoo'
              />

              <p className='text-sm md:text-base w-full text-justify'>
                {`An accomplished pharmaceutical development professional with over two decades of experience in Formulation Research and Development, Validation, Regulatory Affairs, Quality Control and Quality Assurance, Project Management, Green Field Investments in Pharmaceutical Manufacturing, Pharmaceutical Compounding, Business Development, Staff Training and Cross-functional Leadership. Dr. Naseem has held senior managerial roles with Ranbaxy Laboratories (Sun Pharma), India; LaGray Chemical Company, Ghana; Xepa Soul Pattinsons, Malaysia; Sauad Faramceutica, Brazil; Blue Nile Pharma, Sudan; Neopharma LLC, Abu Dhabi and Adcan Pharma, Abu Dhabi handling all aspects of pharmaceutical development across multiple jurisdictions, including USA, South America, Europe, India, Africa, South East Asia and the Middle East. Naseem is acting as a technical consultant to the United States Pharmacopoeia to oversee the development of a technical guide for dispersible tablets. He is Permanent Member of the BCS-Biowaiver Focus Group FIP (International Pharmaceutical Federation) which is tasked with the development of biowaiver monographs to support the pharmaceutical industry in developing countries. Naseem has profound knowledge in QbD, Risk Management, Lean Manufacturing Processes, Validation, Pharmacokinetics and Pharmacodynamics. Naseem holds a Ph.D degree in Pharmaceutics and has published over 70 research articles and 20 book chapters`}
              </p>
            </Flex>
            <Flex
              direction={{ initial: 'column-reverse', md: 'row' }}
              gap={{ initial: '4', md: '6' }}
              align='center'
              width='100%'
            >
              <p className='text-sm md:text-base w-full text-justify'>
                {`Shrikant has over 23 years of industry, academic and research experience and held leadership positions with organizations based in MENA region, America, Europe and India. He is currently working as Professor of Practice with Department of Biotechnology at BITS Pilani, Dubai Campus. In addition to this he is/ was consultant to various pharmaceutical, nutraceutical, medical device and clinical research companies like Allucent, USA; Esperer Nutrition, UK; Pattern Intellectual Labs, India; ClinSync Clinical Research, India; Gulf Inject, UAE; and Wellthy Therapeutics, India in the areas of formulation development, technology transfer, pharmacovigilance and clinical pharmacology. He has conducted training programmes for industries like Torrent Pharmaceuticals, India; Cipla, India and BMS, India in the areas of Research Methodology, Biostatistics and QbD. He was instrumental in the design of Masters Programmes and teaching courses in these areas for working professionals of various pharmaceutical companies like Lupin, Cipla, Dr. Reddys and Biocon. Shrikant has supervised 4 PhD students for doctoral work and more than 20 post graduate students for dissertation. Shrikant holds a Master's degree and PhD from BITS, Pilani, India and Diploma in Intellectual Property Rights from National University of Law, Hyderabad, India.`}
              </p>
              <Person
                imgUrl='assets/about/committee/trainers/shrikant.jpg'
                position='Instructor'
                name='Dr. Shrikant Charde'
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Instructors;
