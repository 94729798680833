export const Day1Schedule = [
  {
    index: 1,
    time: '08:00 - 09:00',
    title: 'Registration and Breakfast',
  },
  {
    index: 2,
    time: '09:00 - 09:45',
    title: 'Inaugural Function',
  },
  {
    index: 3,
    time: 'break',
    title: 'Tea Break',
  },
  {
    index: 4,
    time: '10:00 - 11:00',
    title: 'Introduction to QbD, QRM and Regulatory Framework',
  },
  {
    index: 5,
    time: '11:00 - 13:00',
    title:
      'Identification of quality target product profile and critical quality attributes',
  },
  {
    index: 6,
    time: 'break',
    title: 'Lunch Break',
  },
  {
    index: 7,
    time: '14:00 - 15:00',
    title:
      'Link critical material and process parameters to the critical quality attributes',
  },
  {
    index: 8,
    time: '15:00 - 16:30',
    title: 'Principles of Design of Experiments',
  },
  {
    index: 9,
    time: '16:30 - 17:00',
    title: 'BIORAM',
  },
  {
    index: 10,
    time: 'break',
    title: 'Networking over High Tea',
  },
];

export const Day2Schedule = [
  {
    index: 1,
    time: '08:00 - 09:00',
    title: 'Breakfast',
  },
  {
    index: 2,
    time: '09:00 - 11:00',
    title: 'Rish reduction, Risk acceptance, Life cycle management',
  },
  {
    index: 3,
    time: 'break',
    title: 'Tea Break',
  },
  {
    index: 4,
    time: '11:30 - 13:00',
    title:
      'Hands on experience with implementation of QbD to formulation/process development',
  },
  {
    index: 5,
    time: 'break',
    title: 'Lunch Break',
  },
  {
    index: 6,
    time: '14:00 - 15:00',
    title: 'Open Discussion',
  },
  {
    index: 7,
    time: '15:00 - 16:00',
    title:
      'Valedictory Function, Certificate Distribution, Feedback by Participants, Future Programs and Vote of Thanks',
  },
  {
    index: 8,
    time: 'break',
    title: 'Networking over High Tea',
  },
];

export const organizingCommitee = [
  //Patron
  {
    imgUrl: 'assets/about/committee/srinivasan.svg',
    position: 'Patron',
    name: 'Dr. Srinivasan Madapusi',
  },
  //Convernors
  {
    imgUrl: 'assets/about/committee/convenor/neeru.svg',
    position: 'Convenor',
    name: 'Prof. Neeru Sood',
  },
  {
    imgUrl: '/assets/about/committee/convenor/trupti.svg',
    position: 'Convenor',
    name: 'Prof. Trupti Gokhale',
  },
  //Advisory Commitee
  {
    imgUrl: '/assets/about/committee/advisory/ramachandran_subramanian.jpg',
    position: 'Advisory Commitee',
    name: 'Dr. Ramachandran Subramanian',
  },
  {
    imgUrl: '/assets/about/committee/advisory/gomathi_bhavani.jpg',
    position: 'Advisory Commitee',
    name: 'Dr. R Gomathi Bhavani',
  },
  {
    imgUrl: '/assets/about/committee/advisory/sunil_manjrekar.jpg',
    position: 'Advisory Commitee',
    name: 'Dr. Sunil Manjrekar',
  },

  //Commitee
  {
    imgUrl: 'assets/about/committee/mainak_dutta.jpg',
    position: 'Commitee',
    name: 'Dr. Mainak Dutta',
  },
  {
    imgUrl: 'assets/about/committee/pallab_sanpui.jpg',
    position: 'Commitee',
    name: 'Dr. Pallab Sanpui',
  },
  {
    imgUrl: 'assets/about/committee/aditya_sharma.jpeg',
    position: 'Commitee',
    name: 'Dr. Aditya Sharma',
  },
  {
    imgUrl: 'assets/about/committee/debostuti_ghoshdastidar.jpg',
    position: 'Commitee',
    name: 'Dr. Debostuti Ghoshdastidar',
  },
  {
    imgUrl: 'assets/about/committee/black.svg',
    position: 'Commitee',
    name: 'Ms. Lucy Cherian',
  },
  {
    imgUrl: 'assets/about/committee/black.svg',
    position: 'Commitee',
    name: 'Ms. Piyali Maity',
  },
  {
    imgUrl: 'assets/about/committee/black.svg',
    position: 'Commitee',
    name: 'Ms. Akanksha Sood',
  },
  {
    imgUrl: 'assets/about/committee/black.svg',
    position: 'Commitee',
    name: 'Mr. Abdul Razzak Sohail',
  },
  {
    imgUrl: 'assets/about/committee/black.svg',
    position: 'Commitee',
    name: 'Mr. Amsal Muneeb',
  },
  //Website & IT
  {
    imgUrl: 'assets/about/committee/black.svg',
    position: 'Website & IT',
    name: 'Pratham Gajjar',
  },
  {
    imgUrl: 'assets/about/committee/black.svg',
    position: 'Website & IT',
    name: 'Archit Agrawal',
  },
];

export const registrationFees = [
  {
    type: 'Industry Registration Individual',
    earlyBirdRegistraionFee: 'AED 1300',
    registrationFee: 'AED 1500',
  },
  {
    type: 'Industry Registration Package (3 participants)',
    earlyBirdRegistraionFee: 'AED 3500',
    registrationFee: 'AED 4000',
  },
  {
    type: 'Academic Registration Individual',
    earlyBirdRegistraionFee: 'AED 750',
    registrationFee: 'AED 1000',
  },
  {
    type: 'Academic Registration Package (3 participants)',
    earlyBirdRegistraionFee: 'AED 2000',
    registrationFee: 'AED 2500',
  },
  {
    type: 'Students',
    earlyBirdRegistraionFee: 'AED 400',
    registrationFee: 'AED 500',
  },
];

export const bankDetails = [
  { name: 'Account Title', value: 'BITS PILANI FZ LLC' },
  { name: 'Bank', value: 'HSBC' },
  { name: 'Account No', value: '026-307181-001' },
  { name: 'IBAN No', value: 'AE4502 000 000 2630 7181 001' },
  { name: 'Swift Code', value: 'BBMEAEAD' },
  { name: 'Branch', value: 'Main Branch, Dubai, UAE.' },
];
