import { Container, Flex, Grid } from '@radix-ui/themes';

import Person from '../components/Person';
import { organizingCommitee } from '../data/constants';

const OrganizingCommitee = () => {
  const uniquePositions = [
    ...new Set(organizingCommitee.map((member) => member.position)),
  ];

  return (
    <Container
      size='4'
      width='100%'
      align='center'
      py={{ initial: '6', md: '8' }}
      px={{ initial: '4', md: '0' }}
    >
      <Flex
        width='100%'
        align='center'
        direction='column'
        gap={{ initial: '6', md: '8' }}
      >
        {uniquePositions.map((position) => (
          <Flex
            width='100%'
            align='center'
            justify='center'
            direction='column'
            gap={{ initial: '4', md: '6' }}
            key={position}
          >
            <h1 className='text-primaryColor text-2xl md:text-5xl font-bold text-center'>
              {position}
            </h1>

            <Grid
              columns={{ initial: '2', md: '3' }}
              align='center'
              justify='center'
              width='100%'
              gapY='4'
              gapX={{ initial: '4', md: '6' }}
            >
              {organizingCommitee
                .filter((member) => member.position === position)
                .map((member) => (
                  <Person
                    key={member.name}
                    imgUrl={member.imgUrl}
                    // position={member.position}
                    name={member.name}
                  />
                ))}
            </Grid>
          </Flex>
        ))}
      </Flex>
    </Container>
  );
};

export default OrganizingCommitee;
