import { useLocation } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import { Toaster } from './Toaster';
import Landing from '../pages/Landing';
import ScrollToTop from './ScrollToTop';

function Layout({ children }) {
  const { pathname } = useLocation();

  return (
    <main className='min-h-dvh w-screen flex flex-col justify-between bg-secondaryColor text-blackColor'>
      {pathname === '/' && <Landing />}

      <Header />

      {children}

      <Footer />

      <ScrollToTop />

      <Toaster />
    </main>
  );
}

export default Layout;
