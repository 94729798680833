import { Container, Flex, Grid } from '@radix-ui/themes';
import {
  EnvelopeIcon,
  PhoneIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';
import Marquee from 'react-fast-marquee';

const Footer = () => {
  return (
    <Container
      p={{ initial: '6', md: '8' }}
      className='bg-primaryColor text-whiteColor max-h-[400px] bottom-0'
    >
      <Flex direction='column' gap='6' align='center'>
        <div className='text-sm md:text-xl text-white w-full'>
          <Marquee pauseOnHover>
            egistration restricted to 50 participants | Register before July 31,
            2024 to avail early bird discount | Registration will close on
            August 15, 2024 | R
          </Marquee>
        </div>

        <img
          alt='BITS_logo'
          src='/assets/logos/BITSLogoVertical.png'
          className='h-auto w-[50px] md:w-[100px] block md:hidden'
        />

        <Grid
          width='100%'
          align='center'
          className='place-items-center'
          columns={{ initial: '2', md: '3' }}
        >
          <Flex
            width='100%'
            justify='center'
            direction='column'
            align='center'
            gap='4'
          >
            <h1 className='text-sm md:text-2xl font-bold'>@BITSPILANIDUBAI</h1>

            <Flex
              width='100%'
              justify='center'
              direction='row'
              align='center'
              gap='1'
            >
              <a
                href='https://twitter.com/bitspilanidubai'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='/socials/x.png'
                  alt='X'
                  className='w-[25px] md:w-[40px] invert'
                />
              </a>

              <a
                href='https://www.instagram.com/bitspilanidubai/'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='/socials/instagram.png'
                  alt='Instagram'
                  className='w-[25px] md:w-[40px] invert'
                />
              </a>

              <a
                href='https://www.youtube.com/channel/UClKXaO8gB10OObAkoEkyNDQ'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='/socials/youtube.png'
                  alt='Youtube'
                  className='w-[25px] md:w-[40px] invert'
                />
              </a>

              <a
                href='https://www.linkedin.com/school/birla-institute-of-technology-and-science-pilani-dubai/'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='/socials/linkedin.png'
                  alt='LinkedIn'
                  className='w-[25px] md:w-[40px] invert'
                />
              </a>
            </Flex>
          </Flex>

          <img
            alt='BITS_logo'
            src='/assets/logos/BITSLogoVertical.png'
            className='h-auto w-[50px] md:w-[100px] hidden md:block'
          />

          <Flex gap='4' align='start' justify='center' direction='column'>
            <Flex justify='center' direction='row' align='center' gap='4'>
              <PhoneIcon className='size-4 md:size-7' />
              <a
                href='tel:+97142753700'
                className='text-xs md:text-xl font-bold'
              >
                +971 42753700
              </a>
            </Flex>

            <Flex justify='center' direction='row' align='start' gap='4'>
              <EnvelopeIcon className='size-4 md:size-7' />
              <a
                href='mailto:biotechworkshop@dubai.bits-pilani.ac.in?subject=Query: Qbd BITS Pilani, Dubai Campus&body=Hi there,%0D%0AI would like to know more about this event!'
                className='text-xs md:text-xl font-bold w-full'
              >
                biotechworkshop@dubai.bits-pilani.ac.in
              </a>
            </Flex>
            <Flex justify='center' direction='row' align='start' gap='4'>
              <MapPinIcon className='size-4 md:size-7' />
              <a
                href='https://maps.app.goo.gl/t8NnhcuyYspMGMQW8'
                className='text-xs md:text-xl font-bold w-full'
              >
                QbD Workshop,
                <br />
                Department of Biotechnology,
                <br />
                BITS Pilani Dubai Campus,
                <br />
                DIAC, Dubai, UAE
              </a>
            </Flex>
          </Flex>
        </Grid>
      </Flex>
    </Container>
  );
};

export default Footer;
