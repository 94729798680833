import { useState } from 'react';
import { Flex } from '@radix-ui/themes';
import { Button, Dialog, DialogBody } from '@material-tailwind/react';

const Person = ({ imgUrl, position, name, designation, description }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Flex
        direction='column'
        align='center'
        onClick={handleOpen}
        className={
          description ? 'cursor-pointer min-w-[150px]' : 'min-w-[150px]'
        }
      >
        <img
          src={imgUrl}
          alt={name}
          className='w-[150px] h-[150px] rounded-full mb-3 md:mb-5 object-fill bg-blackColor'
        />

        <p className='text-xl md:text-2xl text-center font-medium'>
          {position}
        </p>
        <p className='text-sm md:text-base text-center italic'>{name}</p>
        <p className='text-sm md:text-base text-center italic w-[80%]'>
          {designation}
        </p>
      </Flex>

      <Dialog
        open={description ? open : false}
        handler={handleOpen}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className='bg-secondaryColor'
      >
        <DialogBody>
          <Flex
            direction='column'
            align='center'
            justify='center'
            width='100%'
            className='gap-4 md:gap-6 p-4 md:p-6'
          >
            <h1 className='text-blackColor text-2xl md:text-3xl text-center w-full'>
              {name}
            </h1>

            <Flex
              direction={{ initial: 'column', md: 'row' }}
              align='center'
              width='100%'
              justify='between'
              className='gap-4 md:gap-6'
            >
              <Flex
                direction='column'
                align='center'
                justify='center'
                width='100%'
              >
                <img
                  src={imgUrl}
                  alt={name}
                  className='w-[150px] h-[full] rounded-full mb-3 md:mb-5'
                />
                <p className='text-base md:text-lg text-center font-medium'>
                  {position}
                </p>
                <p className='text-sm md:text-base text-center'>
                  {designation}
                </p>
              </Flex>

              <p className='text-sm md:text-base text-blackColor text-center mb-4 md:mb-6'>
                {description}
              </p>
            </Flex>

            <Button
              variant='gradient'
              onClick={handleOpen}
              className='bg-primaryColor text-whiteColor'
            >
              Close
            </Button>
          </Flex>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default Person;
