import { Container, Flex } from '@radix-ui/themes';

import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const About = ({ id }) => {
  useEffect(() => {
    if (id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  });

  return (
    <Container
      size='4'
      align='center'
      width='100%'
      py={{ initial: '6', md: '8' }}
      px={{ initial: '4', md: '0' }}
    >
      <Flex
        direction='column'
        align='center'
        gap={{ initial: '6', md: '8' }}
        width='100%'
      >
        {/* About workshop */}
        <Flex
          direction='column'
          align='start'
          gap={{ initial: '4', md: '6' }}
          width='100%'
        >
          <h1 className='text-primaryColor text-2xl md:text-5xl font-bold'>
            About the Workshop
          </h1>

          <Flex
            direction={{ initial: 'column', md: 'row' }}
            gap={{ initial: '4', md: '6' }}
            align='center'
            width='100%'
          >
            <img
              src='/assets/about/about_workshop.jpg'
              alt='About1'
              className='w-full md:w-[400px] h-auto'
            />

            <p className='text-sm md:text-base w-full text-justify'>
              {`In the dynamic regulatory landscape, adherence to Quality by Design (QbD) principles stands as a non-negotiable mandate. As per International Council for Harmonisation of Technical Requirements for Pharmaceuticals for Human Use (ICH) guidelines, particularly ICH Q8(R2), Q9, and Q10, QbD is pivotal for ensuring product quality and patient safety, focusing on a complete product lifecycle. This workshop serves as a focal point for industry stakeholders and academia to grasp the intricacies of QbD in alignment with these regulatory frameworks. The workshop will delve into QbD&apos;s core principles, encompassing design, development, and manufacturing phases. Practical demonstrations will elucidate the application of QbD methodologies in optimizing processes and enhancing product quality, vital for compliance with regulatory expectations. Industry professionals benefit from this workshop as a means to recalibrate their understanding of QbD, aligning with evolving regulatory standards. By mastering QbD principles, professionals streamline operations, bolster product quality and ensure compliance. For students and faculty members, this workshop offers a pragmatic perspective, bridging theoretical knowledge with industry application. Exposure to QbD methodologies equips participants with skills suitable for the constantly evolving regulatory landscape.`}
            </p>
          </Flex>
        </Flex>
        {/* Learning Outcomes */}
        <Flex
          direction='column'
          align='start'
          gap={{ initial: '4', md: '6' }}
          width='100%'
        >
          <h1 className='text-primaryColor text-2xl md:text-5xl font-bold'>
            Learning Outcomes
          </h1>

          <Flex
            direction={{ initial: 'column', md: 'row' }}
            gap={{ initial: '4', md: '6' }}
            align='center'
            width='100%'
          >
            <p className='text-sm md:text-base w-full text-justify'>
              At the end of this session, participants will be able to:
              <ul className='list-inside list-disc'>
                <li>Understand what is Quality by design (QbD)</li>
                <li>Identify quality target product profile</li>
                <li>
                  Understand importance of QbD and regulatory guidance endorsing
                  QbD approach (ICH Q8 to Q11)
                </li>
                <li>
                  Identify the critical quality attributes and link critical
                  material and process parameters to the critical quality
                  attributes of the product
                </li>
                <li>
                  Comprehend the impact of reduced product variability via
                  product and process understanding, resulting in quality
                  improvement, risk reduction and productivity enhancement
                </li>
                <li>
                  Design an efficient risk management approach for
                  identification, mitigation and control of risks
                </li>
                <li>
                  Formulate an efficient design space for flexible regulatory
                  approach with opportunity for continuous improvement
                </li>
                <li>Real time batch release opportunity</li>
                <li>Apply QbD in pharmaceutical development</li>
              </ul>
            </p>

            <img
              src='/assets/about/learning_outcomes.png'
              alt='About2'
              className='w-full md:w-[400px] h-auto'
            />
          </Flex>
        </Flex>
        {/* About BITS Pilani, Dubai Campus */}
        <Flex
          direction='column'
          align='start'
          gap={{ initial: '4', md: '6' }}
          width='100%'
        >
          <h1 className='text-primaryColor text-2xl md:text-5xl font-bold'>
            BITS Pilani, Dubai Campus
          </h1>

          <Flex
            direction={{ initial: 'column', md: 'row' }}
            gap={{ initial: '4', md: '6' }}
            align='center'
            width='100%'
          >
            <img
              src='/assets/about/about1.svg'
              alt='About1'
              className='w-full md:w-[400px] h-auto'
            />

            <p className='text-sm md:text-base w-full text-justify'>
              {`BITS Pilani, an Institute of Eminence recognized by the Government of India, is renowned globally for its superior technical education. With campuses in Pilani, Goa, Hyderabad, and Dubai, it exclusively offers Engineering and Technology programs in Dubai. Drawing students from UAE, GCC countries, Asia, Africa, and the Far East, BITS Pilani, Dubai Campus provides B.E., M.E., M.B.A., and Ph.D. programs across various disciplines. The campus boasts state-of-the-art facilities and a diverse international faculty committed to delivering high-quality education in a global setting. `}
              Visit{' '}
              <Link
                className='text-primaryColor hover:underline'
                rel='noreferrer noopener'
                target='_blank'
                to='https://www.bits-pilani.ac.in/dubai/'
              >
                BITS Pilani, Dubai Campus
              </Link>{' '}
              for more information.
            </p>
          </Flex>
        </Flex>
        {/* About Department of Biotechnology */}
        <Flex
          direction='column'
          align='start'
          gap={{ initial: '4', md: '6' }}
          width='100%'
        >
          <h1 className='text-primaryColor text-2xl md:text-5xl font-bold'>
            Department of Biotechnology
          </h1>

          <Flex
            direction={{ initial: 'column', md: 'row' }}
            gap={{ initial: '4', md: '6' }}
            align='center'
            width='100%'
          >
            <p className='text-sm md:text-base w-full text-justify'>
              {`Established in 2007, the Department of Biotechnology at BITS Pilani, Dubai Campus offers B.E. and PhD programs, supported by cutting-edge laboratories for teaching and research. It houses state-of- the-art facilities including specialized instruments for hands-on training of undergraduates. Research thrust areas encompass drug design, formulation development, industrial microbiology, genetic engineering, nanobiotechnology, and computational biology. Faculty members actively collaborate with industry, research organizations, and academic institutions within and outside the UAE, fostering a dynamic environment for innovation and learning. The department emphasizes practical skills development and interdisciplinary approaches to address global challenges in biotechnology and related fields. `}
              Visit{' '}
              <Link
                className='text-primaryColor hover:underline'
                rel='noreferrer noopener'
                target='_blank'
                to='https://www.bits-pilani.ac.in/dubai/biotechnology/'
              >
                Department of Biotechnology
              </Link>{' '}
              for more information.
            </p>

            <img
              src='/assets/about/about2.svg'
              alt='About2'
              className='w-full md:w-[400px] h-auto'
            />
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};

export default About;
