import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineIcon,
  TimelineBody,
} from '@material-tailwind/react';
import { Flex } from '@radix-ui/themes';

const Day = ({ schedule, title, date }) => {
  return (
    <Timeline>
      <TimelineItem>
        <TimelineBody className='[&>span]:hidden flex flex-row items-center justify-start ml-2.5'>
          <Flex
            direction='column'
            gap='2'
            align='center'
            justify='center'
            className='w-[150px] md:w-[200px] h-[150px] md:h-[200px] rounded-full bg-primaryColor'
          >
            <h1 className='text-whiteColor text-2xl md:text-4xl font-bold'>
              {title}
            </h1>
            <p className='text-whiteColor text-lg md:text-xl font-medium'>
              {date}
            </p>
          </Flex>

          <div className='bg-primaryColor w-2 h-36 md:h-40 -mt-20 md:-mt-24' />
        </TimelineBody>
      </TimelineItem>

      {schedule.map((event) => (
        <TimelineItem key={event.index}>
          {event.index !== schedule.length && (
            <TimelineConnector className='[&>span]:bg-primaryColor [&>span]:w-2' />
          )}

          {event.time === 'break' ? (
            <TimelineIcon className='overflow-visible realtive bg-whiteColor border-primaryColor border-2 p-3'>
              <div className='absolute p-3 w-32 md:w-56 -mt-3.5 bg-whiteColor border-primaryColor border-l-0 rounded-r-full border-2' />
            </TimelineIcon>
          ) : (
            <TimelineIcon className='bg-whiteColor border-primaryColor border-2 p-3' />
          )}

          <TimelineBody
            className={`[&>span]:hidden flex flex-row items-center justify-start mb-10 ${
              event.time === 'break'
                ? 'ml-40 md:ml-64 -mt-8 md:-mt-9'
                : 'ml-16 -mt-12'
            }`}
          >
            <Flex
              direction='column'
              gap='1'
              align='start'
              className='text-primaryColor text-sm md:text-base font-medium text-start'
            >
              {event.time !== 'break' && <p>{event.time}</p>}
              <p>{event.title}</p>
            </Flex>
          </TimelineBody>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default Day;
