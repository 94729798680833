import { query, getDocs, collection, where } from 'firebase/firestore';
import { sha256 } from 'crypto-hash';
import { firestore } from '../services/firebase';
import { toDataURL } from 'qrcode';

export const checkDuplicateEntry = async ({ email }) => {
  const currentYear = new Date().getFullYear();
  const q1 = query(
    collection(firestore, `registrations/${currentYear}/qbd`),
    where('email', '==', email)
  );
  const q2 = query(
    collection(firestore, `registrations/${currentYear}/qbd`),
    where('participantOneEmail', '==', email)
  );
  const q3 = query(
    collection(firestore, `registrations/${currentYear}/qbd`),
    where('participantTwoEmail', '==', email)
  );
  const q4 = query(
    collection(firestore, `registrations/${currentYear}/qbd`),
    where('participantThreeEmail', '==', email)
  );

  var isDuplicate = false;

  await Promise.all([getDocs(q1), getDocs(q2), getDocs(q3), getDocs(q4)]).then(
    (querySnapshots) => {
      querySnapshots.forEach((querySnapshot, index) => {
        if (!querySnapshot.empty) {
          isDuplicate = true;
        }
      });
    }
  );

  return isDuplicate;
};

export const getDocument = async ({ email }) => {
  const currentYear = new Date().getFullYear();
  const q = query(
    collection(firestore, `registrations/${currentYear}/qbd`),
    where('email', '==', email)
  );

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs;
};

export const generateQrCodeAsDataUrl = (hashedId) => {
  var qrUrl = '';
  toDataURL(hashedId, function (err, url) {
    qrUrl = url;
  });
  return qrUrl;
};

export const hashValue = async (value) => {
  const localHash = await sha256(value);
  return localHash;
};
