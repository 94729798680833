import { Flex } from '@radix-ui/themes';
import { Link } from 'react-router-dom';

const Landing = () => {
  return (
    <div
      className='flex flex-col items-center justify-end md:justify-center h-dvh w-screen relative z-20'
      style={{
        backgroundImage: 'url(/assets/hero-bg-w-logo.svg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <img
        alt='BITS_logo'
        src='/assets/logos/BITSLogoVertical.png'
        className='block md:hidden absolute z-10 left-4 md:left-7 top-4 md:top-7 h-auto w-[50px] md:w-[70px]'
      />

      {/* <img
        alt='Event_logo'
        src='/assets/logos/BITSLogoVertical.png'
        className='absolute z-10 right-48 top-36 h-auto w-[200px]'
      /> */}

      <div className='absolute z-10 left-4 md:left-7 bottom-4 md:bottom-7 flex flex-row items-center gap-3'>
        <Link
          to='/schedule'
          className='text-xl md:text-3xl text-whiteColor font-medium'
        >
          Schedule
        </Link>
        <p className='text-xl md:text-3xl text-whiteColor font-medium'>|</p>
        {/* <Link
          to='/register'
          className='text-xl md:text-3xl text-whiteColor font-medium'
        >
          Register
        </Link> */}
      </div>

      <div className='grid grid-cols-4 md:grid-cols-8 pl-4 md:pl-7 pb-28 md:pb-0'>
        <Flex direction='column' align='start' className='col-span-3'>
          <h2 className='text-[20px] md:text-[30px] lg:text-[45px] text-whiteColor font-bold'>
            Pharmaceutical Development:
            <br />
            Integrating Quality by Design
            <br />& Biopharmaceutic Risk Assessment
          </h2>
        </Flex>
      </div>
    </div>
  );
};

export default Landing;
